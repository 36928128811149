import Vue from 'vue';
import Vuex from 'vuex';
import serviceCapacity from './modules/service-capacity';
import modelE from './modules/model-e';
import recall from './modules/recall';
import recallQuestions from './modules/recall-questions';
import universal from './modules/universal';
import reports from './modules/reports';
import validation from './modules/validation';
import actionPlanSurvey from './modules/action_plan_survey';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    serviceCapacity,
    modelE,
    recall,
    recallQuestions,
    universal,
    reports,
    validation,
    actionPlanSurvey,
  },
});
