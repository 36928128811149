export default {
  namespaced: true,
  state: {
    pollReports: false,
  },
  actions: {
    async updatePollReports({ commit }, value) {
      commit('setPollReports', value);
    },
  },
  getters: {},
  mutations: {
    setPollReports(state, value) {
      state.pollReports = value;
    },
  },
  strict: process.env.NODE_ENV !== 'production',
};
