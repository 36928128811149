<template>
  <div id="app">
    <notifications />
    <router-view></router-view>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue';

export default {
  name: 'App',
  components: {},
  watch: {
    $route(to) {
      if (to.path.includes('model-e-assessment')) {
        this.$router.push({ name: 'ServiceCapacityAssessment' });
      }
    },
  },
  created() {
    if (this.$route.path.includes('model-e-assessment')) {
      this.$router.push({ name: 'ServiceCapacityAssessment' });
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
html,
body,
#app {
  height: 100%;
  overflow: hidden;
}

@media print {
  body,
  html,
  #app,
  #home {
    margin: 0;
    background-color: white;
    font-family: 'FordF1-Regular';
  }
  .main {
    border-top: 0 !important;
    background-color: white;
  }
  .right {
    padding-top: 0 !important;
    background-color: white;
  }
}
</style>
