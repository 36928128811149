import Vue from 'vue';
import Router from 'vue-router';
import AuthService from '@/services/auth.service';

import JwtService from '@/services/jwt.service';

Vue.use(Router);

const routes = [
  {
    path: '/',
    component: () => import('../components/Home.vue'),
    children: [
      {
        path: 'service-capacity-assessment',
        name: 'ServiceCapacityAssessment',
        component: () => import('../components/ServiceCapacityAssessment.vue'),
        children: [
          {
            path: 'service-capacity-solutions',
            name: 'ServiceCapacitySolutions',
            component: () =>
              import(
                '../views/service-capacity-assessment/service-capacity-solutions/index.vue'
              ),
          },
          {
            path: 'instructions',
            name: 'Instructions',
            component: () =>
              import(
                '../views/service-capacity-assessment/instructions/index.vue'
              ),
          },
          {
            path: 'dealership-selection',
            name: 'DealershipSelection',
            component: () =>
              import(
                '../views/service-capacity-assessment/dealership-selection/index.vue'
              ),
          },
          {
            path: 'dealer/:dealer_id/assessment/:assessment_id/dealership-consensus',
            name: 'DealershipConsensus',
            component: () =>
              import(
                '../views/service-capacity-assessment/dealership-consensus/index.vue'
              ),
          },
          {
            path: 'dealer/:dealer_id/retail-traffic-assessment',
            name: 'RetailTrafficAssessment',
            component: () =>
              import(
                '../views/service-capacity-assessment/retail-traffic-assessment/index.vue'
              ),
          },
          {
            path: 'dealer/:dealer_id/assessment/:assessment_id/action-plan',
            name: 'ActionPlan',
            component: () =>
              import(
                '../views/service-capacity-assessment/action-plan/index.vue'
              ),
          },
          {
            path: 'dealer/:dealer_id/report-export-and-history',
            name: 'ReportExportAndHistory',
            component: () =>
              import(
                '../views/service-capacity-assessment/report-export-and-history/index.vue'
              ),
          },
          {
            path: 'report-metrics',
            name: 'ReportAnalytics',
            component: () =>
              import(
                '../views/service-capacity-assessment/report-analytics/index.vue'
              ),
          },
          {
            path: 'dealer/:dealer_id/ro-analytics',
            name: 'ROAnalytics',
            component: () =>
              import(
                '../views/service-capacity-assessment/ro-analytics/index.vue'
              ),
          },
          {
            path: 'dealer/:dealer_id/ro-analytics-export',
            name: 'ROAnalyticsExport',
            component: () =>
              import(
                '../views/service-capacity-assessment/ro-analytics-export-history/index.vue'
              ),
          },
          {
            path: 'dealer/:dealer_id/assessment/:assessment_id/investigation-guide',
            name: 'InvestigationGuide',
            component: () =>
              import(
                '../views/service-capacity-assessment/investigation-guide/index.vue'
              ),
          },
          {
            path: 'dealer/:dealer_id/assessment/:assessment_id/investigation-guide-export-and-history',
            name: 'InvestigationGuideExportAndHistory',
            component: () =>
              import(
                '../views/service-capacity-assessment/investigation-guide-export-and-history/index.vue'
              ),
          },
        ],
      },
      // {
      //   path: 'model-e-assessment',
      //   name: 'ModelEAssessment',
      //   component: () => import('../components/ModelEAssessment.vue'),
      //   children: [
      //     {
      //       path: 'model-e',
      //       name: 'ModelE_ModelE',
      //       component: () =>
      //         import('../views/model-e-assessment/model-e/index.vue'),
      //     },
      //     {
      //       path: 'instructions',
      //       name: 'ModelE_Instructions',
      //       component: () =>
      //         import('../views/model-e-assessment/instructions/index.vue'),
      //     },
      //     {
      //       path: 'dealership-selection',
      //       name: 'ModelE_DealershipSelection',
      //       component: () =>
      //         import(
      //           '../views/model-e-assessment/dealership-selection/index.vue'
      //         ),
      //     },
      //     {
      //       path: 'model-e-assessment/:dealer_id/:assessment_id',
      //       name: 'ModelE_ModelEAssessment',
      //       component: () =>
      //         import(
      //           '../views/model-e-assessment/model-e-assessment/index.vue'
      //         ),
      //     },
      //     {
      //       path: 'action-plan-export-history/:dealer_id/:assessment_id',
      //       name: 'ModelE_ActionPlanExportHistory',
      //       component: () =>
      //         import(
      //           '../views/model-e-assessment/action-plan-export-history/index.vue'
      //         ),
      //     },
      //     {
      //       path: 'reports',
      //       name: 'ModelE_Reports',
      //       component: () =>
      //         import('../views/model-e-assessment/reports/index.vue'),
      //     },
      //     {
      //       path: 'survey/:assessment_id',
      //       name: 'ModelE_ActionPlanSurvey',
      //       component: () => import('../components/ActionPlanSurvey.vue'),
      //     },
      //   ],
      // },
      {
        path: 'recall_assessment',
        name: 'RecallAssessment',
        component: () => import('../components/recall/RecallAssessment.vue'),
        children: [
          {
            path: 'information',
            name: 'Recall_Information',
            component: () =>
              import(
                '../views/recall_assessment/recall-completion-acceleration/index.vue'
              ),
          },
          {
            path: 'dealership-selection',
            name: 'Recall_DealershipSelection',
            component: () =>
              import(
                '../views/recall_assessment/dealership-selection/index.vue'
              ),
          },
          {
            path: 'assessment/:dealer_id/:assessment_id',
            name: 'Recall_Assessment',
            component: () =>
              import('../views/recall_assessment/recall_tracker/index.vue'),
          },
          {
            path: 'action-plan-export-history/:dealer_id/:assessment_id',
            name: 'Recall_ActionPlanExport',
            component: () =>
              import(
                '../views/recall_assessment/action-plan-export-history/index.vue'
              ),
          },
          {
            path: 'reports',
            name: 'Recall_Reports',
            component: () =>
              import('../views/recall_assessment/reports/index.vue'),
          },
        ],
      },
      {
        path: '/dealer-landing',
        name: 'DealerLanding',
        component: () => import('../components/DealerLanding.vue'),
      },
    ],
  },
  {
    path: '/adfs/callback',
    name: 'ADFSCallback',
    component: () => import('../views/adfs-callback/index.vue'),
  },
  {
    path: '/authorize',
    name: 'Authorize',
    component: () => import('../views/adfs-callback/index.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/authentication/login'),
  },

  // {
  //   path: '/unauthorized',
  //   name: 'Unauthorized',
  //   component: () => import('../components/Unauthorized.vue'),
  // },
];

const checkAuth = (to, from, next) => {
  if (to && to.query.resource) {
    const resourceUuid = to.query.resource;
    JwtService.saveRedirectResourceUuid(resourceUuid);
  }

  if (to.path === '/authorize' || to.path === '/adfs/callback') {
    if (to.query.status_code !== '403' && to.query.status_code !== '406') {
      try {
        // console.log("103")
        const token = to.query.token;
        // console.log(token);
        const data = JSON.parse(token);
        // console.log(data);
        AuthService.registerUserSession(data);
        next({ path: '/' });
      } catch {
        // console.log("106")
        next({ path: '/login', query: { status_code: '406' } });
      }
    } else if (to.query.status_code === '406') {
      // console.log("111");
      JwtService.destroyToken();
      next({ path: '/login', query: { status_code: '406' } });
    } else {
      // console.log("115");
      next({ path: '/login' });
    }
  } else {
    // console.log("119");
    // console.log(to.path);
    AuthService.checkAuth(to, from, next);
  }
};

const router = new Router({
  mode: 'history',
  routes,
});

router.beforeEach(checkAuth);

export default router;
